.members{
    border-color: #d7b679;
    border: solid;
    color: #ef600A;
    width: max-content;
}


.text-members{
    font-size: 60px;
    color: white;
}

.p-total-cashier{
    color: #69f369
}

.p-expense-cashier{
    color: #f38847
}

.card{
    background-color: #202b2e;
    border-radius: 10px;
}