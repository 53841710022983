label{
    margin-top: 2px;
    font-family: 'HurmeGeometricSans3W01-Lt';
    color: white;
    font-size: larger;
}
.app-menu {
    height: 100vh; 
    display: flex
}
.default-form
{
  border-radius: 10px;
  border: #324B4B;
  padding:20px;
  background-color: #202b2e;
  width: fit-content;
}

.formLabel{
    margin-bottom: 30px;
    font-weight: bold;
}

.default-field {
    border-radius: 10px !important;
    align-self: center;
}

.common-header{
    font-size: xx-large;
}

.alert-error {
    margin-top: 5px;
    color: red !important;
}

.alert-success {
    margin-top: 5px;
    color: rgb(239 96 10);
}

.edit-a{
    color: white;
}

.edit-a:hover{
    color: #f97321;
}

.button-default{
    margin-top: 10px !important;
    border-radius: 10px !important;
    background-color: #ef600a;
    border-color: #ef600a;
}

.img-fluid{
    
}


@font-face {font-family: "HurmeGeometricSans3W01-Lt"; 
    src: url("//db.onlinewebfonts.com/t/6fe41ddfdaa27520c7b0d4cfe2564195.eot"); 
    src: url("//db.onlinewebfonts.com/t/6fe41ddfdaa27520c7b0d4cfe2564195.eot?#iefix") format("embedded-opentype"), 
    url("//db.onlinewebfonts.com/t/6fe41ddfdaa27520c7b0d4cfe2564195.woff2") format("woff2"), 
    url("//db.onlinewebfonts.com/t/6fe41ddfdaa27520c7b0d4cfe2564195.woff") format("woff"), 
    url("//db.onlinewebfonts.com/t/6fe41ddfdaa27520c7b0d4cfe2564195.ttf") format("truetype"), 
    url("//db.onlinewebfonts.com/t/6fe41ddfdaa27520c7b0d4cfe2564195.svg#HurmeGeometricSans3W01-Lt") format("svg"); }
