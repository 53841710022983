.sidebar-menu {
    background: white;
    font-family: 'HurmeGeometricSans3W01-Lt';
}
h1 {
    font-family: 'HurmeGeometricSans3W01-Lt';
    margin: 50px auto;
    font-size: 40px;
}
.sidebar-header {
    margin-bottom: 40px;
    margin-top: 20px;
}
h2 {
    font-family: 'HurmeGeometricSans3W01-Lt';
}
.menu-item {
    /* background-color: #91c7ff; */
    background-color: #cecece;
}

.ps-collapsed {
    min-width: 80px !important;
}