.income-type-form
{
    background-color: #202b2e
}

.label-search{
    margin-right: 20px;
}

.button-search{
    background-color: #f97321;
    border-color: transparent !important;
}

.button-search:hover{
    background-color: white !important;
    color: #202b2e;
}

.td-options{
    width: 10%;
}