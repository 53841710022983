.text{
    margin-top: 2px;
    font-family: 'HurmeGeometricSans3W01-Lt';
    color: white;
    font-size: larger;
    text-align: left;
}

.carousel-card{
    height: 730px;
}

.carousel-item.active{
    display: inline-flex;
}

.carousel-item-next{
    display: inline-flex;
}

.carousel-item-previous{
    display: inline-flex;
}


.text-header{
   color: white !important;
   font-family: 'HurmeGeometricSans3W01-Lt'; 
   font-weight: bold;
   text-align: left !important;
   margin-top: 20px;
   font-size: 60px;
}
.text-pastor{
    color: white !important;
    font-family: 'HurmeGeometricSans3W01-Lt'; 
    text-align: center !important;
    margin-top: 20px;
 }


.img-logo{
    max-width: 60% !important;
    margin-top: 20px;
    margin-bottom: 10px;
}


.card{
    border-radius: 15px;
    background: #010400;
    margin-top: 25px;
}

.img-background{
    background-repeat: 'no-repeat';
}

.hero{
    margin-top: 55px;
    height: 250px;
}


.hero-text{
    color: white;
    font-family: 'HurmeGeometricSans3W01-Lt';
    margin-top: 30px;
    font-size: 25px;
    text-align: left;
}


.black-card-section{
    background-color: white !important;
    height: fit-content;
    margin-top: 20px;
    padding: 10px;
}

.white-text{
   color: white;
   font-family: 'HurmeGeometricSans3W01-Lt'; 
}

.valores{
    height: 350px;
}

.white-card-header{
    color: white !important;
    font-family: 'HurmeGeometricSans3W01-Lt'; 
    font-weight: bold;
    text-align: center !important;
    margin-top: 20px;
 }

.white-card{
    border-radius: 15px;
    background: white;
    margin-top: 25px;
}

.white-card-text-title{
    color: #010400 !important;
    font-family: 'HurmeGeometricSans3W01-Lt'; 
}

.endereco{
    height: fit-content;
}

.black-card-text-title{
    color: #010400 !important;
    font-family: 'HurmeGeometricSans3W01-Lt'; 
    font-weight: bold;
    text-align: center !important;
    margin-top: 20px;
 }

.text-header-endereco{
    color: white !important;
    font-family: 'HurmeGeometricSans3W01-Lt'; 
    font-weight: bold;
    text-align: center !important;
    margin-top: 20px;
 }

html {
    scroll-padding-top: 70px;
  }

  .a-redes{
    color: rgb(255, 255, 255) !important
  }

.img-spotify{
    max-width: 50% !important;
    margin-top: 20px;
    margin-bottom: 10px;
}

.img-movimentese{
    height: 500px
}

.text-endereco{
    margin-top: 2px;
    font-family: 'HurmeGeometricSans3W01-Lt';
    color: white;
    font-size: larger;

}

.video{
    margin-top: 10px;
    margin-bottom: 20px;
    height: fit-content;
    text-align: center;
}

.black-section{
    height: fit-content;
    margin-top: 20px;
}

.black-section-text{
    color: #010400 !important;
    font-family: 'HurmeGeometricSans3W01-Lt';
}

@media only screen and (max-width: 1280px) {
    .text-endereco{
        margin-top: 10px;
        font-family: 'HurmeGeometricSans3W01-Lt';
        color: white;
        font-size: small;
    }
    
    .carousel-card{
        height: 1050px;
    }

    .img-movimentese {
        height: 213px;
    }

    .img-spotify{
        max-width: 100% !important;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .valores {
        height: 800px !important;
    }

    .viver {
        height: 800px !important;
    }

    .text-header{
        color: white !important;
        font-family: 'HurmeGeometricSans3W01-Lt'; 
        font-weight: bold;
        text-align: left !important;
        margin-top: 1px;
        font-size: 30px;
    }

    .hero {
        height: 400px !important;
    }

    .endereco{
        margin-top: 1px;
        background-color: black;
        height: fit-content;
    }

    .video{
        margin-top: 20px;
        height: fit-content;
    }

    .carousel-item.active{
        display: block;
    }
    
    .carousel-item-next{
        display: block;
    }

}

@media only screen and (max-width: 820px) {
    .video{
        margin-top: 20px;
        height: fit-content;
    }
    .img-movimentese {
        height: 213px;
    }
}

@media only screen and (max-width: 375px) {
    .video{
        margin-top: 20px;
        height: fit-content;
    }
    
    .img-movimentese {
        height: 213px;
    }
}

