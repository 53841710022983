.nav-img-left {
    margin-left: 20px;
}

.nav-img-right {
    margin-right: 20px;
}

.nav-logo-ipb {
    margin-right: 20px;
}

.img-nav {
    max-width: 10% !important;
}

.nav-landing {
    height: fit-content;
    font-family: 'HurmeGeometricSans3W01-Lt';
    background: white;
}

@media only screen and (max-width: 1280px) {
    .nav-logo-ipb {
        margin-right: 20px;
        height: 80px;
    }
}

@media only screen and (max-width: 820px) {
    .nav-logo-ipb
    {
        margin-right: 20px;
        height: 80px;
    }
}

@media only screen and (max-width: 375px) {

    .nav-logo-ipb
    {
        margin-right: 20px;
        height: 80px;
    }
}